@use "../../SCSS/color-variables";

.driver {
  height: auto;
  border-radius: 10px;
}

.driver:nth-child(n + 2) {
  margin-top: 0.3rem;
}

.topThree {
  border-radius: 25px;
}

.blackBG {
  background-color: color-variables.$black;
}

.greyBG {
    background-color: color-variables.$lightGrey;
}

.dText {
  margin: 0;
}

.p2 
{
    padding-top: 1rem;
}

.p3
{
    padding-top: 1.5rem;
}

.rank {
  width: 25px;
}

.teamColor {
  width: 4px;
  height: 1.2rem;
}

.flag {
  width: 16px !important;
  height: 12px !important;
}

.topFlag {
  width: 40px !important;
  height: 30px !important;
}

/******* Team Colors *******/
.ferrari {
  background-color: color-variables.$ferrari;
}
.mercedes {
  background-color: color-variables.$mercedes;
}
.haas {
  background-color: color-variables.$haas;
}
.mclaren {
  background-color: color-variables.$mclaren;
}
.aston {
  background-color: color-variables.$aston;
}
.alfa {
  background-color: color-variables.$alfa;
}
.redbull {
  background-color: color-variables.$redbull;
}
.alpine {
  background-color: color-variables.$alpine;
}
.alphatauri {
  background-color: color-variables.$alphatauri;
}
.williams {
    background-color: color-variables.$williams;
}
.dzp {
  background: color-variables.$dzp;
}

@media screen and (max-width: 900px)
{
    .topThreeDrivers
    {
        display: none;
    }
}