@use "../../SCSS/color-variables";
@use "../../SCSS/mixins";

.hero {
  @include mixins.hero("../../Images/dzpgames_web_bg.png");
}

.s3BgImage {
  background: url(../../Images/dzpgames_web_bg.png) no-repeat center/cover;
}

.lrBorder {
  border-left: solid 1px color-variables.$white;
  border-right: solid 1px color-variables.$white;
}

.s3
{
    .image
    {
        height: 70px;
        margin-bottom: 15px;
    }
}


@media screen and (max-width: 880px)
{
    .s3
    {
        .col3
        {
            flex-direction: column;

            > div
            {
                margin-bottom: 2rem;
            }

            .lrBorder
            {
                padding: 0.667rem;
                border: none;
                border-top: solid 1px color-variables.$white;
                border-bottom: solid 1px color-variables.$white;
            }

            > div:last-child
            {
                margin-bottom: 0;
            }
        }
    }

    .s5
    {
        .row2
        {
            flex-direction: column;
            
            > div 
            {
                padding: 0.667rem;
            }
        }
    }
}