@use "../../SCSS/color-variables";
@use "../../SCSS/mixins";

.hero {
  @include mixins.hero("../Images/champions_hero.jpg");
  height: 60vh;
}

.spacer {
  height: 55px;
}

.button {
  width: fit-content;
  margin: auto;
  margin-top: 3rem;
  padding: 1rem;
  display: block;
  border: none;
  border-radius: 10px;
  background-color: color-variables.$mcLaren;
  text-decoration: none;
  text-shadow: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: color-variables.$magenta;
    color: color-variables.$white;
  }
}

.slider {
  min-height: 290px;
  display: flex;

  .track {
    padding: 0.5rem 1rem;
    border-left: solid 1px color-variables.$lightGrey;
    transition: background-color 0.3s ease, border-color 0.3s ease,
      color 0.3s ease;
    cursor: pointer;

    &:hover,
    &.active {
      background-color: color-variables.$lightGrey;
      border-color: color-variables.$mcLaren;
    }
  }

  .leftTh {
    width: 30%;
    margin-right: 1%;
  }

  .leftTh + .right {
    width: 78%;
  }

  .right {
    position: relative;
    height: 290px;
  }

  .slide {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;

    &.active {
      opacity: 1;
    }

    h3 {
      text-shadow: none;
    }
    p {
      margin: 0.5rem 0 1rem;
    }
    ul {
      padding-left: 1rem;
    }

    img {
      width: 75%;
      margin: auto;
      display: block;
    }
  }
}

.dTitle {
  span {
    margin-left: 1rem;
    display: inline-block;
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 880px) {
  .s2 {
    .col3 {
      flex-direction: column;

      > div {
        margin-bottom: 2rem;
      }

      .lrBorder {
        padding: 0.667rem;
        padding-top: 2rem;
        border: none;
        border-top: solid 1px color-variables.$white;
        border-bottom: solid 1px color-variables.$white;
      }

      > div:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .slider {
    min-height: auto;
    flex-wrap: wrap;
    overflow: hidden;

    .leftTh,
    .leftTh + .right {
      width: 100%;
    }

    .leftTh {
      margin-bottom: 1.5rem;

      .track {
        padding: 1rem;
        border: none;
        border-bottom: solid 1px color-variables.$lightGrey;

        &:hover,
        &.active {
          border-color: color-variables.$mcLaren;
        }
      }
    }
  }

  .dTitle {
    span {
      margin: 0;
      display: block;
    }
  }
}

@media screen and (max-width: 400px) {
  .slider {
    .right {
      min-height: 370px;
    }
  }
}
