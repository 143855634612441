.card {
  height: auto;
  margin: auto;
  text-align: center;
}

.cardTextSh {
  margin-bottom: -15px;
  padding: 20px;
}

.card.xs {
  max-width: 300px;
}
