nav .contain {
  width: 90%;
}

.brand-text {
  @media (min-width: 768px) and (max-width: 999px) {
    display: none;
  }
}

.nav-item.dropdown {
  margin-right: 15px;
}

.nav-item {
  padding-right: 1rem;
  color: #fff;
  transition: color 0.3s ease;
  cursor: pointer;

  &:hover {
    color: #0085c2;
  }

  .nav-link {
    color: #fff;
    transition: color 0.3s ease;

    &:hover {
      color: #0085c2;
    }
  }
}

.nav-item:last-child {
  padding-right: 0;
}
