$magenta: #e724b0;
$cyan: #00afff;
$black: #151515;
$lightGrey: #4c4c4c;
$white: #fff;

/******* Other Colors *******/
$success: #3cf281;

/******* DZP Colors *******/
$darkPurple: #572d6a;
$darkMagenta: #990799;
$darkCyan: #0085c2;
$mcLaren: #ffaa00;
$darkGrey: #353535;

/******* Team Colors *******/
$ferrari: #f91536;
$mercedes: #6cd3bf;
$haas: #b6babd;
$mclaren: #f58020;
$aston: #358c75;
$alfa: #c92d4b;
$redbull: #3671c6;
$alpine: #2293d1;
$alphatauri: #5e8faa;
$williams: #37bedd;
$dzp: linear-gradient(
  180deg,
  rgba(0, 133, 194, 1) 0%,
  rgba(153, 7, 153, 1) 100%
);
