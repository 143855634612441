/************************
          DZP           
************************/

@font-face {
  font-family: "Odudo Mono Lt";
  src: url("./fonts/OdudoMono-Light.woff") format("woff");
}
@font-face {
  font-family: "Odudo Mono";
  src: url("./fonts/OdudoMono-Regular.woff") format("woff");
}
@font-face {
  font-family: "Odudo Mono Semi";
  src: url("./fonts/OdudoMono-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Odudo Mono Bold";
  src: url("./fonts/OdudoMono-Bold.woff") format("woff");
}
@font-face {
  font-family: "Montserrat Black";
  src: url("./fonts/Montserrat/static/Montserrat-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat Black Italic";
  src: url("./fonts/Montserrat/static/Montserrat-BlackItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat Bold";
  src: url("./fonts/Montserrat/static/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat Bold Italic";
  src: url("./fonts/Montserrat/static/Montserrat-BoldItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat Extra Bold";
  src: url("./fonts/Montserrat/static/Montserrat-ExtraBold.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat Extra Bold Italic";
  src: url("./fonts/Montserrat/static/Montserrat-ExtraBoldItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat Extra Light";
  src: url("./fonts/Montserrat/static/Montserrat-ExtraLight.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat Extra Ligth Italic";
  src: url("./fonts/Montserrat/static/Montserrat-ExtraLightItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat Italic";
  src: url("./fonts/Montserrat/static/Montserrat-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat Light";
  src: url("./fonts/Montserrat/static/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat Light Italic";
  src: url("./fonts/Montserrat/static/Montserrat-LightItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat Medium";
  src: url("./fonts/Montserrat/static/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat Medium Italic";
  src: url("./fonts/Montserrat/static/Montserrat-MediumItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat Regular";
  src: url("./fonts/Montserrat/static/Montserrat-Regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat Semi Bold";
  src: url("./fonts/Montserrat/static/Montserrat-SemiBold.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat Semi Bold Italic";
  src: url("./fonts/Montserrat/static/Montserrat-SemiBoldItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat Thin";
  src: url("./fonts/Montserrat/static/Montserrat-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat ThinItalic";
  src: url("./fonts/Montserrat/static/Montserrat-ThinItalic.ttf")
    format("truetype");
}
@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat/Montserrat-VariableFont_wght.ttf")
    format("truetype");
}
/* This is a Variable Font
   Typical values for font-weight are 100, 200, 300, 400, 500, 600, 700, 800, and 900,
   where 400 is usually the normal weight and 700 is bold. You can choose any value between these,
   for example, 450 or 650, to get an intermediate weight.
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 18px;
  letter-spacing: 0.05em;
  line-height: 25.2px;
  text-align: left;
  text-shadow: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html body, #root 
{
    background: #4c4c4c;
    color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
.navbar-brand {
  font-variant-caps: small-caps;
}

@media screen and (max-width: 767px)
{
    body
    {
        font-size: 16px;
    }
}